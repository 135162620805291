@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background: #1b1509;
}

body {
    max-height: 100vh;
}

.fields .labels label {
    line-height: .1rem !important;
}
